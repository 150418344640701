/* ::-webkit-scrollbar{
  width: 0px;
} */

.perent-conatiner{
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center ;
    height: 350vh;
    color:  rgb(112, 112, 112);
}

.container{
    height: 300vh;
    
}

.plus-sign {
    position: absolute;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    transition: transform 0.1s, top 0.1s;
    position:fixed;
  }
  
  .plus-sign .vertical, .plus-sign .horizontal {
    position: absolute;
    background-color: white;
    border: 5px solid black;
    transition: all 0.1s;
  }
  
  .plus-sign .vertical {
    width: 20px;
    height: 100px;
    top: 0;
    left: 40px;
  }
  
  .plus-sign .horizontal {
    width: 100px;
    height: 20px;
    top: 40px;
    left: 0;
  }
  

  .box{
    width: 20px ;
    height: 20px;
    background-color: white;
  }














  /* NEw */
  .new-box{
    position: relative;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    transition: scale 1s ease-in-out;
    animation: fadeIn 3s;
  }

  @keyframes zoomIn {
    from{
      /* scale: 0.1; */
      width: 20px;
      height: 20px;
      top:40%;
    }
    to{
      /* scale: 1; */
      width: 100vw;
      height: 100vh;
      top: 0%;
    }
  }
  
  .new-box-2{
    animation: fadeRev 1s;
  }
  @keyframes fadeIn {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  @keyframes fadeRev {
    from{
      width: 100vw;
      height: 100vh;
      top: 0%;
    }
    to{
      width: 20px;
      height: 20px;
      top:40%;
    }
  }

  .new-box-horizontel{
    position: absolute;
    width: 20px;
    height: 100px;
    left: 40px;
    top: 0px;
    background-color:transparent;
    border: 2.5px solid  rgb(112, 112, 112);
  }

  .new-box-vertical{
    position: absolute;
    width: 100px;
    height: 20px;
    left: 0px;
    top: 40px;
    background-color:transparent;
    border: 2.5px solid  rgb(112, 112, 112);
  }

  .parent-content-container{
    display: flex;
    flex-direction: row;
    padding: 200px 64px;
  }

  .parent-content-container-2{
    gap:150px;
  }

  .parent-content-container-3{
    position: sticky;
    position: -webkit-sticky;
    top: -155px;
  }

  .parent-content-container-left{
    width: 45%;
    color: rgb(112, 112, 112);
  }

  .parent-content-container-left-2{
    width: 25%;
  }
  
  .parent-content-container-left h1{
    font-size: 35px;
    font-weight: 200;
  }

  .parent-content-container-right{
    width: 55%;
  }
  
  .parent-content-container-right-2{
    width: 55%;
  }
  
  .parent-content-container-right h1{
    font-family: "Nunito Sans", sans-serif;
    color: rgb(112, 112, 112);
    font-size: 25px;
    font-weight: 200;
  }

  .new-divi{
    width: 100%;
    height: 100vh;
    background-color: rgb(199, 198, 198);
    color:  rgb(112, 112, 112);
    position: absolute;
    z-index: 7990;
    top: 0;
    border: 2.5px solid  rgb(112, 112, 112);
    animation: fadeIn 2s;
  }

  .new-divi-2{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: justify;
    
  }

  .no-scroll {
    overflow: hidden;
  }

  .actual-content{
    width: 100%;
    
  }

  .test-contents{
    padding: 0 100px;
  }

  /* Scroll Effect */

  /* Container for horizontal scrolling */
  /* .container {
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  
  .vertical-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  }
  
  .horizontal-container {
    display: flex;
    width: 300vw;
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }
  
  .horizontal-section {
    scroll-snap-align: start;
    flex: none;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2e2e2;
    border: 1px solid #ccc;
  } */

  .flight-animation-main-container{
    margin-top: 50px;
  }