.footer-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: hidden;
    width: 100%;
    padding: 20px 0 0 20px;
    border-top: 1px solid darkgray;
}

.footer-container-01{
    width: 50%;
    padding: 50px 50px;
}

.footer-container-02{
    width: 50%;
    padding-right: 50px;
}

.footer-container-03{
    width: 30%;
    position: relative;
    height: 270px;
}

.footer-container-01-image{
    margin-bottom: 10px;
}

.footer-container-01 p{
    margin-bottom: 30px;
}

.footer-container-01-btn{
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    background-color: rgb(105, 105, 105);
    border-radius: 25px;
}

.footer-container-02-lists-container{
    display: flex;
    flex-direction: row;
    gap: 60px;
    padding: 50px 10px;
}

.footer-container-02-lists-container p{
    margin-bottom: 10px;
    color: rgb(75, 75, 75);
    font-weight: 700;
}

.footer-container-02-lists-container ul{
    margin-left: 20px;
}

.footer-container-02-lists-container li{
    margin-bottom: 7px;
}

.footer-container-02-lists-container a{
    text-decoration: none;
    color: rgb(75, 75, 75);
}

.footer-container-02-lists-container-02{
    margin-top: 30px;
}

.footer-container-03 video{
    position: absolute;
    top: -150px;
    right: -120px;
}

.footer-copy-right-section{
    padding: 40px;
    border-top: 1.5px solid rgb(105, 105, 105);
}

