/* ScrollEffect.css */

.container-sticy-element {
  display: flex;
  width: 100%;
  margin: 50px 0;
}

.left {
  width: 30%;
  border: 2.5px solid darkgray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 300px;
  position: sticky;
  top: 25%;
}

.right {
  width: 80%;

}

.content {
  padding: 0 20px;
}

.content p {
  margin: 0 0 20px 0;
  border: 2.5px solid darkgray;
  padding: 15% 20px;
  color: darkgray;
  border-radius: 5px;
}

.content p:nth-of-type(3) {
  margin: 0;
}