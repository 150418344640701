@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
}

::-webkit-scrollbar{
    width: 10px;
}

.foo-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 99vh;
    margin: 5px;
    position: relative;
}

.foo {
    color: rgb(112, 112, 112);
    border:2.5px solid rgb(112, 112, 112);
    text-align: center;
    font-size: 14px;
    animation: MotionScale 1.3s ;
    height: 100vh;
    width: 100%;
    
    /* transform:matrix3d(
        0.5070346953451985,	-0.04142038603075953,	0,	-0.00026327533476106803,
        0.12119124287791651,	0.9704898401576015,	0,	0.0003948762072134976,
        0,	0,	1,	0,
        139,	59,	0,	1
        );
        transform-origin: 0px 0px 0px;
        width: 80%;
        height: 80%; */
}
  
  @keyframes MotionScale {
    from {
        transform: matrix3d(
            0.5930813638828653,	-0.0012963075175383022,	0,	0.000005226497624311837,
            0.01655611986520036,	0.8612964312738794,	0,	0.000036955678579156266,
            0,	0,	1,	0,
            145,	51,	0,	1
            );
        transform-origin: 0px 0px 0px;
        width: 40%;
        height: 40%;
    }
    50% {
        transform:matrix3d(
            0.5070346953451985,	-0.04142038603075953,	0,	-0.00026327533476106803,
            0.12119124287791651,	0.9704898401576015,	0,	0.0003948762072134976,
            0,	0,	1,	0,
            139,	59,	0,	1
        );
        transform-origin: 0px 0px 0px;
        width: 80%;
        height: 80%;
    }
    100%{
        transform: none;
        width: 100%;
        height: 100%;
    }
    
  }

  .foo-content{
    width: 100%;
    padding: 10px;
  }

  .nav-bar-icon{
    text-align: right;
    overflow: hidden;
    padding-right: 20px;
    padding-top: 10px;
  }

  .nav-bar-icon svg{
    cursor: pointer;
    color: rgb(112, 112, 112);
  }

  .foo-content-body{
    display: flex;
    flex-direction: row;
    
  }

  .foo-content-body-left{
    width: 25%;
    padding-top: 15%;
    padding-left: 3%;
    text-align: start;
    
  }

  .foo-content-body-left h1{
    font-size: 54px;
    font-weight: 200;
  }

  .foo-content-body-right{
    width: 75%;
    padding-top: 5%;
    overflow: hidden;
  }

.foo-header-contents{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.foo-header-logo-conatiner img{
  padding-top: 10px;
  width: 70%;
}


/* NEw */
.new-box{
  position: relative;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  transition: scale 1s ease-in-out;
  animation: fadeIn 3s;
}

@keyframes zoomIn {
  from{
    /* scale: 0.1; */
    width: 20px;
    height: 20px;
    top:40%;
  }
  to{
    /* scale: 1; */
    width: 100vw;
    height: 100vh;
    top: 0%;
  }
}

.new-box-2{
  animation: fadeRev 1s;
}
@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes fadeRev {
  from{
    width: 100vw;
    height: 100vh;
    top: 0%;
  }
  to{
    width: 20px;
    height: 20px;
    top:40%;
  }
}

.new-box-horizontel{
  position: absolute;
  width: 20px;
  height: 100px;
  left: 40px;
  top: 0px;
  background-color:transparent;
  border: 2.5px solid  rgb(112, 112, 112);
}

.new-box-vertical{
  position: absolute;
  width: 100px;
  height: 20px;
  left: 0px;
  top: 40px;
  background-color:transparent;
  border: 2.5px solid  rgb(112, 112, 112);
}

.parent-content-container{
  display: flex;
  flex-direction: row;
  padding: 200px 64px;
}

.parent-content-container-2{
  gap:150px;
}

.parent-content-container-3{
  position: sticky;
  position: -webkit-sticky;
  top: -155px;
}

.parent-content-container-left{
  width: 45%;
  color: rgb(112, 112, 112);
}

.parent-content-container-left-2{
  width: 25%;
}

.parent-content-container-left h1{
  font-size: 35px;
  font-weight: 200;
}

.parent-content-container-right{
  width: 55%;
}

.parent-content-container-right-2{
  width: 55%;
}

.parent-content-container-right h1{
  font-family: "Nunito Sans", sans-serif;
  color: rgb(112, 112, 112);
  font-size: 25px;
  font-weight: 200;
}

.new-divi{
  width: 100%;
  height: 100vh;
  background-color: rgb(199, 198, 198);
  color:  rgb(112, 112, 112);
  position: absolute;
  z-index: 7990;
  top: 0;
  border: 2.5px solid  rgb(112, 112, 112);
  animation: fadeIn 2s;
}

.new-divi-2{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: justify;
  
}

.no-scroll {
  overflow: hidden;
}

.actual-content{
  width: 100%;
  
}

.test-contents{
  padding: 0 100px;
}

/* Scroll Effect */

/* Container for horizontal scrolling */
/* .container {
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.vertical-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.horizontal-container {
  display: flex;
  width: 300vw;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.horizontal-section {
  scroll-snap-align: start;
  flex: none;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e2e2;
  border: 1px solid #ccc;
} */

.flight-animation-main-container{
  margin-top: 50px;
}