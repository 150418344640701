*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  
  overscroll-behavior: none;
  -ms-overflow-style: none;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  will-change: transform;
  overflow-y: hidden;
}

.scroll-container .ghost {
  width: 100vw;
}

.thumbnails-container {
  position: relative;
  height: 100vh;
  width: max-content;
  display: flex;
  align-items: center;
  background-color: white;
}

.thumbnails-container .thumbnails {
  position: relative;
  display: flex;
  border: 2.5px solid rgb(112, 112, 112);
}

.thumbnails-container .thumbnails .thumbnail {
  height: 90vh;
  width: 96vw;
  border: 2.5px solid rgb(112, 112, 112);
  margin: 2.5px;
  padding: 15px;
}

@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.thumbnail-parent-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.thumbnail h3{
  color: rgb(112, 112, 112);
}


.thumbnail-content-container{
  display: flex;
  flex-direction: row;
  gap: 5%;
  padding: 0px 10px 30px;
}

.thumbnail-content-container-left{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thumbnail-content-container-left-heading-underline{
  background-color: rgb(112, 112, 112);
  height: 2.5px;
  width: 50%;
  margin: 10px 0 20px;
}

.thumbnail-content-container-right{
  width: 50%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-content-container-right img{
  width: 90%;
  height: 100%;
}